<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <GreetingHeader />
        <ion-buttons slot="primary">
          <ion-button
            color="light"
            router-link="/main/search"
          >
            <IonIcon
              slot="icon-only"
              :icon="search"
            />
          </ion-button>
          <ion-button
            color="light"
            router-link="/main/profile?tab=profile"
          >
            <IonIcon
              slot="icon-only"
              :icon="personCircle"
            />
          </ion-button>
          <ion-button
            color="light"
            router-link="/main/profile?tab=settings"
          >
            <IonIcon
              slot="icon-only"
              :icon="settingsSharp"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh($event)"
      >
        <ion-refresher-content/>
      </ion-refresher>

      <div
        id="container"
        class="m-3"
      >
        <InviteCard class="tablet-centered mb-16-lg" />
        <section
          v-if="!isLoading"
          class="home-order-wrapper"
        >
          <app-lazy :style="getStyleOrderPosition('recently_played')" >
            <RecentlyPlayedSlider />
          </app-lazy>
          <app-lazy :style="getStyleOrderPosition('popular_courses')" >
            <PopularCoursesCarousel/>
          </app-lazy>
          <app-lazy :style="getStyleOrderPosition('popular_streams')">
            <PopularStreamsCarousel
              :title="$t('home.topStreamTitle')"
              :popular-data="popularWeekly"
            /> </app-lazy>
          <app-lazy :style="getStyleOrderPosition('new_releases_podcast')">
            <PopularStreamsCarousel
              :title="$t('home.newPodcastReleases')"
              :popular-data="newPodcastReleases"
            /> </app-lazy>
          <app-lazy :style="getStyleOrderPosition('recommended')">
            <PopularStreamsCarousel
              :title="$t('home.recommendedStreams')"
              :popular-data="recommendedStreams"
            /> </app-lazy>
          <FeaturedSlidersContainer :featured-sliders-data="featuredCourses" />

          <app-lazy :style="getStyleOrderPosition('new_releases')">
            <NewReleasesCarousel /> </app-lazy>
          <app-lazy :style="getStyleOrderPosition('following')">
            <HomeFollowing />
          </app-lazy>

        </section>

        <div class="footer ion-text-center">
          <div class="podcast-studio">
            <IonImg
              src="./assets/podcast_studio.png"
              style="width: 100%"
            />
            <div class="podcast-studio-text fw-700">{{$t('home.ownPodcast')}}</div>
          </div>
          <ion-button
            mode="ios"
            shape="round"
            class="contact-btn fw-500 fz-18"
            :href="JIRA_CONTACT_LINK"
            target="_blank"
          >
            {{ $t('commonKeys.contact') }}
          </ion-button>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import { watchEffect, watch } from "@vue/runtime-core";
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { JIRA_CONTACT_LINK } from '@/config/constants.js';

import {
  IonImg,
  IonPage,
  IonList,
  IonIcon,
  IonItem,
  IonTitle,
  IonSlide,
  IonHeader,
  IonButton,
  IonSlides,
  IonButtons,
  IonToolbar,
  IonContent,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  onIonViewDidEnter,
} from '@ionic/vue';

import {
  personCircle,
  settingsSharp,
  search,
} from 'ionicons/icons';


import AppImg from '@/components/common/AppImg.vue';
import AudioPlayer from '@/components/player/AudioPlayer';
import NewPodcast from '@/components/common/NewPodcast.vue';
import InviteCard from '@/components/common/InviteCard.vue';
import HomeFollowing from "@/components/home/HomeFollowing";
import GreetingHeader from "@/components/home/GreetingHeader";
import NewReleasesCarousel from "@/components/home/NewReleasesCarousel";
import PopularStreamsCarousel from "@/components/home/PopularStreamsCarousel";
import PopularCoursesCarousel from "@/components/home/PopularCoursesCarousel";
import RecentlyPlayedSlider from '@/components/recently-played/RecentlyPlayedSlider.vue';
import FeaturedSlidersContainer from '@/components/featured/FeaturedSlidersContainer.vue';


import { INIT_APP_NETWORK_CONNECTED, TRACKING_HOME } from "@/config/constants";
import { waitTime } from "@/helpers/functions";
import useTracking from "@/composables/useTracking";
import take from 'lodash/take';
import AppLazy from "@/components/common/AppLazy";


export default defineComponent({
  name: 'Home',
  components: {
    AppLazy,
    IonPage,
    IonList,
    IonItem,
    IonIcon,
    IonImg,
    IonTitle,
    IonSlide,
    IonHeader,
    IonButton,
    IonSlides,
    IonContent,
    IonToolbar,
    IonButtons,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,

    AppImg,
    NewPodcast,
    InviteCard,
    AudioPlayer,
    HomeFollowing,
    GreetingHeader,
    NewReleasesCarousel,
    RecentlyPlayedSlider,
    PopularStreamsCarousel,
    PopularCoursesCarousel,
    FeaturedSlidersContainer,
  },

  setup() {
    const store = useStore();
    const { trackThis } = useTracking();

    const orderConfig = computed(() => store.state.app.homeOrderConfig);

    trackThis(TRACKING_HOME);

    const slideOptions = { slidesPerView: 'auto' };

    const isLoading = ref(true);

    const featuredCourses = computed(() => store.state.featuredCourses.featuredCoursesDetails);
    const popularWeekly = computed(() => {
      const recentlyPlayedStorageIds = store.state.player.recentlyPlayed.map(el => el.id);
      return take(store.getters['library/getPopularStreamsWeekly'].filter(el => !recentlyPlayedStorageIds.includes(el.id)), 10);
    });
    const newPodcastReleases = computed(() => {
      const recentlyPlayedStorageIds = store.state.player.recentlyPlayed.map(el => el.id);
      return take(store.getters['library/getNewPodcastReleases'].filter(el => !recentlyPlayedStorageIds.includes(el.id)), 10);
    });

    const recommendedStreams = computed(() => {
      const recentlyPlayedStorageIds = store.state.player.recentlyPlayed.map(el => el.id);
      return take(store.getters['library/getRecommendedStreams'].filter(el => !recentlyPlayedStorageIds.includes(el.id)), 10);
    });

    const getNewReleases = () => store.dispatch('newReleases/setNewReleasesHome');
    const getProfileDetails = async () => store.dispatch('profile/setProfileDetails');
    const getFeaturedCourses = async () => {
      let professions = [];
      try {
        professions = (await store.dispatch('profile/setUserProfessions')).data;
        // eslint-disable-next-line no-empty
      } catch (e) {}
      const params = {
        professions: professions.map(el => el.profession_id),
      };
      await store.dispatch('featuredCourses/setFeaturedDetails', params);
    };
    const getRecentlyPlayed = () => store.dispatch('recentlyPlayed/setRecentlyPlayedDetails');
    const getFollows = () => store.dispatch('favorites/setFollowingList');
    const getRecentlyPlayedStorage = () => store.dispatch('player/setRecentlyPlayedStorage');
    const getPopularWeeklyStreams = () => store.dispatch('library/setPopularStreamsWeekly');
    const getPopularCourses = () => store.dispatch('library/setPopularCourses');
    const getLastPodcastReleases = () => store.dispatch('library/setNewPodcastStreamReleases');
    const getRecommendedStreams = () => store.dispatch('library/setRecommendedStreams');

    onIonViewDidEnter(() => {
      if (isLoading.value) {
        setTimeout(() => {
          isLoading.value = false;
        }, 0);
      }
    });

    const fetchAllData = () => {
      getProfileDetails();
      getNewReleases();
      getFeaturedCourses();
      getRecentlyPlayed();
      getFollows();
      getRecentlyPlayedStorage();
      getPopularWeeklyStreams();
      getPopularCourses();
      getLastPodcastReleases();
      getRecommendedStreams();
    };

    onMounted(() => {
      fetchAllData();
    });

    watch(
      () => store.state.favorites.followersUpdated,
      async (newValue) => {
        if (newValue) {
          // same watcher in MainFollowing.vue page, for preventing double fetching used delay
          await waitTime(250);
          if (store.state.favorites.followersUpdated) {
            getFollows();
            store.commit('favorites/setFollowingUpdated', false);
          }
        }
      },
    );

    const refresh = async (ev) => {
      fetchAllData();
      ev.target.complete();
    };

    watchEffect(() => {
      const newValue = store.state.app.network.appInitState;
      // console.log('INIT_APP_NETWORK_CONNECTED const is', INIT_APP_NETWORK_CONNECTED);
      // console.log('BOOL IS', newValue === INIT_APP_NETWORK_CONNECTED);
      if (newValue === INIT_APP_NETWORK_CONNECTED) {
        // console.log('we go INSIDE FETCH DATA');
        fetchAllData();
      }
    });


    watch(
      () => store.state.app.appActive,
      (appActive) => {
        const platform = store.state.app.platform;
        if (appActive && platform !== 'web') {
        //  console.log('HOME PAGE: app active changed new state:', appActive, 'fetching home data');
          fetchAllData();
        }
      });


    const getStyleOrderPosition = (el) => {
      const order = orderConfig.value[el] ?? 0;
      return { order };
    };


    return {
      search,
      personCircle,
      settingsSharp,

      isLoading,
      slideOptions,
      popularWeekly,
      featuredCourses,
      newPodcastReleases,
      recommendedStreams,

      refresh,
      getStyleOrderPosition,
      JIRA_CONTACT_LINK,
    };
  },
});
</script>

<style scoped lang="scss">
.new-wrap {
  flex-wrap: wrap;
}

.title {
  color: var(--ion-color-primary);
}
.footer {
  margin: 30px 0px;

  .podcast-studio {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
  .podcast-studio-text {
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 40px;
  }
  .contact-btn {
    --padding-start: 40px;
    --padding-end: 40px;
    margin-top: 20px;
  }
}
</style>
