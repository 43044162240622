<template>
  <div class="account-component">
    <ProfileEmail />

    <MobileNumberInput />

    <div class="bottom-buttons-wrap is-flex is-flex-direction-column ion-align-items-center">
      <ion-button
        mode="ios"
        class="uppercase --border-radius account-btn"
        fill="outline"
        @click="switchTwoFA"
      >
        {{ twoFAEnabled ? $t('profile.twoFADisable') : $t('profile.twoFAEnable') }}
      </ion-button>
      <ion-button
        mode="ios"
        class="uppercase --border-radius account-btn"
        fill="outline"
        @click="deleteAccount"
      >
        {{ $t('profile.deleteAccount.sectionTitle') }}
      </ion-button>
      <ion-button
        mode="ios"
        class="uppercase --border-radius account-btn"
        fill="outline"
        @click="changePassword"
      >
        {{ $t('profile.changePassword') }}
      </ion-button>
      <ion-button
        mode="ios"
        class="uppercase --border-radius account-btn"
        fill="outline"
        @click="logout"
      >
        {{$t('logout.label')}}
      </ion-button>
      <ion-button
        mode="ios"
        class="uppercase --border-radius account-btn"
        fill="outline"
        :href="JIRA_CONTACT_LINK"
        target="_blank"
      >
        {{$t('commonKeys.contact')}}
      </ion-button>
      <ion-button
        mode="ios"
        class="uppercase --border-radius account-btn"
        fill="outline"
        @click="openModal"
      >
        {{$t('invitationCard.title')}}
      </ion-button>
    </div>
    <div class="legal-info">
      <IonImg
        src='assets/logo.png'
        style="height: 55px"
      />
      <p v-if="version">Version {{ version }}</p>
      <p class="fz-12 fw-500">Copyright © {{ new Date().getFullYear() }} MedischeScholing</p>
      <p class="fz-12 fw-500">
        <a
          href="#"
          @click.prevent="openUrl('https://medischescholing.nl/transparantie-verklaring')"
        >
          {{ $t('profile.privacyDeclaration') }}
        </a>
      </p>
      <p class="fz-12 fw-500">
        <a
          href="#"
          @click.prevent="openUrl('https://medischescholing.nl/algemene-voorwaarden')"
        >
          {{ $t('profile.termsAndConditions') }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';

import { IonButton, modalController, alertController, IonImg } from '@ionic/vue';
import ProfileEmail from "@/components/profile/email/ProfileEmail";
import ProfileChangePasswordModal from "@/components/profile/ProfileChangePasswordModal";
import ProfileAccountDeleteModal from "@/components/profile/ProfileAccountDeleteModal";
import TwoFASwitcherModal from "@/components/profile/two-fa/TwoFASwitcherModal";
import MobileNumberInput from "@/components/profile/MobileNumberInput";
import useVersionCheker from "@/composables/useVersionCheker";
import {computed, ref} from '@vue/reactivity';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n/index';
import useInAppBrowser from '@/composables/useInAppBrowser';
import axios from '@/http';
import { GET_INVITE_TOKEN } from '@/config/api';
import ShareModal from '@/components/common/ShareModal';
import { App } from '@capacitor/app';
import { checkPlatform } from "@/helpers/functions";
import { JIRA_CONTACT_LINK } from '@/config/constants.js';

export default {
  name: 'ProfileAccount',
  components: {
    MobileNumberInput,
    ProfileEmail,
    AppInput,
    IonButton,
    IonImg,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { getCurrentVersion } = useVersionCheker();
    const { openUrl } = useInAppBrowser();

    const version = ref(null);

    const changePassword = async () => {
      const modal = await modalController.create({ component: ProfileChangePasswordModal });
      await modal.present();
    };
    const deleteAccount = async () => {
      const modal = await modalController.create({ component: ProfileAccountDeleteModal });
      await modal.present();
    };

    const twoFAEnabled = computed(() => !!store.getters['authentication/getUser']?.optional_2fa_enabled);

    const switchTwoFA = async () => {
      const modal = await modalController.create({ component: TwoFASwitcherModal });
      await modal.present();
    };

    const logout = async () => {
      const alert = await alertController
        .create({
          header: t('logout.dialogPromptTitle'),
          message: t('logout.dialogPromptMessage'),
          buttons: [
            { text: t('savedCourses.dialogCancelText') },
            {
              text: t('savedCourses.dialogConfirmText'),
              handler: () => store.dispatch('authentication/logout'),
            },
          ],
        });
      return alert.present();
    };

    const openModal = () => {
      getInviteToken().then((token) => {
        openShareModal(token);
      });
    };
    const getInviteToken = () => {
      return new Promise((resolve, reject) => {
        axios
          .get(GET_INVITE_TOKEN)
          .then(({ data }) => {
            resolve(data.invite_token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    const openShareModal = async (token) => {
      const modal = await modalController.create({
        component: ShareModal,
        componentProps: {
          shareLink: `${process.env.VUE_APP_SHARE_URL}/invite/${token}`,
          shareText: t('invitationCard.shareText', { token }),
          showLink: false,
          modalTitle: t('invitationCard.shareInvite'),
        },
        cssClass: 'share-modal',
      });
      await modal.present();
    };

    onMounted(async() => {
      checkPlatform().then(async platform => {
        if (platform !== 'web') {
          const info = await App.getInfo();
          version.value = info.version;
        }
      });
    });

    return {
      changePassword,
      deleteAccount,
      switchTwoFA,
      getCurrentVersion,
      version,
      logout,
      openUrl,
      openModal,
      twoFAEnabled,
      JIRA_CONTACT_LINK,
    };
  },
};
</script>

<style lang="scss" scoped>
  .email-wrap {
    /*row-gap: 10px;*/
  }

  .mobile-wrap {
    margin-top: 20px;
    /*row-gap: 10px;*/
  }

  .bottom-buttons-wrap {
    margin-top: 50px;
    /*row-gap: 15px;*/
    > *:not(:last-child){
      margin-bottom: 15px;
    }
  }

  .legal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*row-gap: 15px;*/
    padding: 30px 0;

    > *:not(:last-child){
      margin-bottom: 15px;
    }
  }

  .input {
    width: 100%;
  }
</style>

<style>
  .account-btn {
    width: 260px;
  }
</style>
