<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <div
      class="is-flex is-flex-direction-column ion-justify-content-between"
      style="height: 100%"
    >
      <ion-header class="ion-padding-horizontal ion-padding-vertical">
        <div class="pt-6">
          <div class="is-flex ion-justify-content-center">
            <AppImg
              is-transparent
              img-src="./assets/medicast-logo.png"
              style="width: 100px; height: 100px"
            />
          </div>

          <h2 class="title fw-900 mb-3 ion-text-center">Medicast</h2>
          <h3 class="subtitle fw-700 ion-text-center">MedischeScholing</h3>
          <h5 class="fw-400 mb-3 ion-text-center">
            {{ $t('versionAlert.description') }}
          </h5>
        </div>
      </ion-header>

      <section class="ion-padding-vertical ion-padding-horizontal">
        <div class="mt-5 is-flex buttons-wrap">
          <ion-button
            fill="outline"
            expand="block"
            mode="ios"
            shape="round"
            class="fw-500 fz-18 uppercase"
            @click="close"
          >
            {{ $t('commonKeys.cancel') }}
          </ion-button>
          <ion-button
            expand="block"
            mode="ios"
            shape="round"
            class="fw-500 fz-18 uppercase"
            @click="submit"
          >
            {{ $t('commonKeys.update') }}
          </ion-button>
        </div>
        <div class="is-flex ion-justify-content-center">
          <ion-button
            mode="ios"
            fill="clear"
            class="uppercase fw-500 mt-5 fz-12 contact-us"
            target="_blank"
            :href="JIRA_CONTACT_LINK"
          >
            {{ $t('contact.us') }}
          </ion-button>
        </div>
      </section>
    </div>
  </ion-content>
</template>

<script>
import {
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import { JIRA_CONTACT_LINK } from '@/config/constants.js';

export default {
  name: 'VersionUpdateModal',
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
    AppImg,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    submit: {
      type: Function,
      default: () => {},
    },
  },
  data () {
    return {
      JIRA_CONTACT_LINK,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 50px;
}
.subtitle {
  font-size: 20px;
  letter-spacing: 2.7px;
  margin-bottom: 50px;
}

.buttons-wrap {
  ion-button {
    margin: 0 7px;
  }
}

.buttons-wrap ion-button {
  width: 50%;
}
</style>
