<template>
  <ion-content class="top-safe-space">
    <div class="is-flex is-flex-direction-column h-100">
      <div class="is-flex ion-align-items-center ion-padding-top">
        <IonIcon
          :icon="chevronBackOutline"
          size="large"
          class="pointer"
          @click="close"
        />
        <h4 class="fw-700 uppercase ion-padding-start">{{$t('accreditation.request')}}</h4>
      </div>

      <section
        class="ion-margin tablet-centered"
        style="overflow-y: auto"
      >
        <div class="main-content pt-5">
          <PossibleProfessions />
          <p class="fz-12 fw-300 py-2">{{ $t('accreditation.steps') }}</p>
          <p class="fz-12 fw-300 py-2">{{ $t('accreditation.checkDetails') }}</p>
          <div class="content">
            <AppInput
              :placeholder="$t('profile.firstNameLabel')"
              :value="firstName"
              :errors="errors?.first_name"
              @input:update="firstName = $event"
            />
            <AppInput
              :placeholder="$t('profile.insertionLabel')"
              :value="insertion"
              @input:update="insertion = $event"
            />
            <AppInput
              :placeholder="$t('profile.lastNameLabel')"
              :value="lastName"
              :errors="errors?.last_name"
              @input:update="lastName = $event"
            />
            <div class="user-professions">
              <h4 class="fw-700 mb-2 uppercase">{{$t('verifyProfile.profession.label')}}</h4>
              <UserProfessions
                :professions="getUserProfessions"
                :fetch-user-professions-fn="() => $store.dispatch('profile/setUserProfessions')"
                :delete-user-profession-fn="(object) => $store.dispatch('profile/removeBignumber', object.id)"
                :add-user-profession-fn="(object) => $store.dispatch('profile/addBignumber', object)"
                :update-user-profession-fn="(object) => $store.dispatch('profile/updateBignumber', object)"
                can-add-profession
                can-delete-profession
                can-edit-profession
                :show-title="false"
                :profession-messages="userProfessionValidatedList"
              />
            </div>
            <AppInput
              :icon="mailOutline"
              :placeholder="$t('profile.emailLabel')"
              style="width: 100%"
              readonly
              :value="userEmail"
            >
              <template #inner-right-addon>
                <IonIcon
                  :icon="checkmarkDone"
                  :color="emailVerified ? 'success' : null"
                  class="mr-4"
                />
              </template>
            </AppInput>
          </div>
          <div class="mt-5 ion-text-right">
            {{ $t('contact.need_assistance') }}
            <a
              :href="JIRA_CONTACT_LINK"
              target="_blank"
            >
              {{ $t('commonKeys.here') }}
            </a>
          </div>
          <div class="buttons-wrap mt-5 is-flex ion-justify-content-between">
            <ion-button
              fill="outline"
              mode="ios"
              class="fw-500 fz-18 uppercase"
              @click="close"
            >
              {{ $t('followCompanies.deletePromptCancel') }}
            </ion-button>
            <ion-button
              mode="ios"
              class="fw-500 fz-18 uppercase"
              @click="confirmProfile"
            >
              {{ $t('commonKeys.confirm') }}
            </ion-button>
          </div>
        </div>
      </section>
    </div>
  </ion-content>
</template>

<script>
import PossibleProfessions from '@/components/accreditation/PossibleProfessions';
import AppInput from '@/components/common/AppInput.vue';
import UserProfessions from "@/components/profile/professions/UserProfessions";
import ProfileEmail from "@/components/profile/email/ProfileEmail";

import { modalController, IonContent, IonIcon, IonButton, IonHeader, IonToolbar, IonButtons } from '@ionic/vue';
import { computed, reactive, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { mailOutline, checkmarkDone, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n/index';
import { watch } from "@vue/runtime-core";
import useRequestToast from '@/composables/useRequestToast';
import axios from '@/http';
import { QUESTIONNAIRE_UPDATE_ACCREDITATION_DATA } from '@/config/api';
import { JIRA_CONTACT_LINK } from '@/config/constants.js';

export default {
  name: 'VerifyInformationModal',
  components: {
    PossibleProfessions,
    AppInput,
    UserProfessions,
    ProfileEmail,
    IonIcon,
    IonContent,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
  },

  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    onConfirm: {
      type: Function,
      default: undefined,
    },
    streamId: {
      type: [String, Number],
      default: "",
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const { openErrorToast, openSuccessToast } = useRequestToast();

    const userProfessionValidatedList = ref([]);
    const errors = ref(null);

    const profileDetailsFetched = computed(() => store.getters['profile/profileDetailsFetched']);
    const getProfessions = computed(() => store.getters['course/getCourseData'].professions);
    const getUserProfessions = computed(() => store.getters['profile/getUserProfessions']);
    const getCourseId = computed(() => store.state.questionnaires.courseId);
    const courseProfessions = computed(() => store.state.course.validAccreditationProfessions);

    const firstName = computed({
      get() {
        return store.state.profile.profileDetails.first_name;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { first_name: val });
      },
    });
    const insertion = computed({
      get() {
        return store.state.profile.profileDetails.initials;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { initials: val });
      },
    });
    const lastName = computed({
      get() {
        return store.state.profile.profileDetails.last_name;
      },
      set(val) {
        store.commit('profile/updateProfileDetails', { last_name: val });
      },
    });
    const userEmail = computed( () => store.getters['authentication/getUser'].email);
    const emailVerified = computed( () => store.getters['authentication/getUser'].email_verified_at);

    const confirmProfile = () => {
      const profileData = {
        first_name: firstName.value,
        last_name: lastName.value,
        insertion: insertion.value,
        email: userEmail.value,
        update_accreditation_user_data_only: true,
        professions: getUserProfessions.value.map(profession => ({ id: profession.profession_id })),
        stream_id: props.streamId,
      };
      axios
        .post(QUESTIONNAIRE_UPDATE_ACCREDITATION_DATA, profileData)
        .then(() => {
          errors.value = null;
          openSuccessToast(t('profile.profileUpdatedMessage'));
          store.commit('accreditation/setConfirmedProfile', true);
          props.onConfirm && props.onConfirm();
          props.close();
        })
        .catch((err) => {
          errors.value = err?.response?.data?.errors;
          openErrorToast(err);
        });
    };

    const validateProfessionsForAccreditation = () => {
      userProfessionValidatedList.value = [];

      getUserProfessions.value.forEach((userProfession) => {
        const currentUserProfessionStatus = courseProfessions.value.find(courseProfession => courseProfession.id === userProfession.profession_id);

        const professionWithoutAccreditation = !currentUserProfessionStatus;
        const professionAccreditationOutdated = !professionWithoutAccreditation && !currentUserProfessionStatus?.valid_by_dates;

        const result = {
          profession_id: userProfession.profession_id,
          name: userProfession.profession_details?.name,
          type: 'success',
          message: t('streams.professionValidationReasons.accreditationPossible'),
        };

        if (professionWithoutAccreditation) {
          result.message = t('streams.professionValidationReasons.noAccreditationForProfession');
          result.type = 'danger';
        }
        if (professionAccreditationOutdated) {
          result.message = currentUserProfessionStatus?.message || t('streams.professionValidationReasons.noAccreditationForProfession');
          result.type = 'danger';
        }

        userProfessionValidatedList.value.push(result);
      });
    };
    const checkProfessionsForAccreditation = () => {
      store.dispatch('course/getValidAccreditationProfessions', getCourseId.value).then(validateProfessionsForAccreditation);
    };

    checkProfessionsForAccreditation();

    watch(
      () => getUserProfessions.value,
      () => {
        validateProfessionsForAccreditation();
      },
    );

    if (!profileDetailsFetched.value) {
      store.dispatch('profile/setProfileDetails');
    }


    return {
      firstName,
      insertion,
      lastName,
      checkmarkDone,
      mailOutline,
      userEmail,
      emailVerified,
      chevronBackOutline,
      getProfessions,
      confirmProfile,
      getUserProfessions,
      userProfessionValidatedList,
      errors,
      JIRA_CONTACT_LINK,
    };
  },
};
</script>

<style scoped lang="scss">
.main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content > *:not(:last-child) {
  margin-bottom: 16px;
  overflow: hidden;
}
.buttons-wrap ion-button {
  --padding-start: 25px;
  --padding-end: 25px;
}
</style>

<style lang="scss">
.proffesion-select {
  ion-select {
    --padding-start: 0;
    --placeholder-opacity: 0.5;
  }
}
.verify-information-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}
.verify-information-modal::part(content) {
  width: 100%;
  height: 100%;
}
</style>
